<template>
    <header>
        <div class="header">
            <div class="main pt-2 top-menu">
                <div class="logo" style="width: 450px">
                    <router-link to="/">
                        <img src="/img/main_logo.png" alt="c2c logo" width="100%" />
                    </router-link>
                </div>
                <div class="user">
                    <router-link class="item" to="/about" style="white-space: nowrap;">
                        {{ $t("menu.usage_guide") }}
                    </router-link>
                    <template v-if="isLogged">
                        <Notification :notification="notification" />
                        <router-link class="item" :to="{ name: 'my-page' }">
                            {{ $t("menu.my_page") }}
                        </router-link>
                        <a href="#" class="item" @click="_toSell">
                            {{ $t("menu.to_sell") }}
                        </a>
                    </template>
                    <template v-else style="min-width: 15rem">
                        <button class="btn btn-login w-100" @click="_login">
                            {{ $t("menu.login") }}
                        </button>
                        <button class="btn btn-register w-100" @click="_register">
                            {{ $t("menu.register") }}
                        </button>
                    </template>
                </div>
            </div>
        </div>
        <RegisterModal :isVisible="this.isVisible" @cancel="actionCancel" />
    </header>
</template>

<script>
import Notification from './components/notification.vue';

export default {
    data() {
        return {
            isVisible: false,
            keyword: ""
        }
    },
    props: {
        user: {},
        notification: {},
    },
    components: {
        Notification,
    },
    computed: {
        isLogged() {
            return this.getMemberId() || this.user.info;
        }
    },
    methods: {
        _toSell() {
            let { info, stripe } = this.user;
            if (!info) {
                this.isVisible = true;
                return;
            }
            if (info.role == "teacher" &&
                stripe.is_account_verify == true) {
                this.$router.push("/my-page/lesson");
            } else {
                this.$router.push("/to-sell");
            }
        },
        actionCancel() {
            this.isVisible = false;
        },
        _login() {
            window.$("#mms-sign-in").click()
        },
        _register() {
            window.$("#mms-sign-up").click()
        },
        _search(e) {
            e.preventDefault();
            this.$router.push("/c/all?search=" + this.keyword)
        }
    },
};
</script>

<style scoped>
header {
    position: sticky;
    top: 2rem;
    z-index: 999;
    background-color: #FFFFFF;
}

.header {
    margin-top: 0;
}
</style>

<template>
  <div>
    <div class="popup-modal" role="dialog" v-if="isVisible">
    <div class="wrapper modal-reminder">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div></div>
            <h5 class="modal-title">予約時間内です</h5>
            <div></div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="_cancel"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="item">
                <div class="card">
                    <div class="card-header">
                        <div class="info">{{ $t(`lesson.${reminder.level}`)}}</div>
                        <div class="card-title">{{ reminder.title }}</div>
                    </div>
                    <div class="card-body" id="table">
                        <div class="tr">
                            <div class="td text-title">
                                {{ $t('lesson.datetime')}}
                            </div>
                            <div class="td w-100 text-descr">
                              {{ formatDateJa(reminder.start_datetime, "YYYY年MMMMDD日") }} {{ _formatTime(reminder.start_datetime, reminder.end_datetime) }}
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td text-title">
                                {{ $t('lesson.teacher')}}
                            </div>
                            <div class="td w-100 text-descr">
                                {{ reminder.teacher_name }}
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td text-title">
                                {{ $t('lesson.student')}}
                            </div>
                            <div class="td w-100 text-descr">
                                {{ reminder.student_name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center action-block">
                    <h5 class="footer-title">相談リストページから、相談に進んでください。</h5>
                    <a href="/my-page/list-lesson" 
                        class="btn btn-primary">
                        相談リストへ進む
                    </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
 
</template>

<script>
export default {
  props: [
  ],
  name: "ReminderModal",
  data() {
    return {
      reminder: {},
      isVisible: false,
    };
  },
  created() {
    //this.isVisible = true;
    let memberId = this.getMemberId();
    if(memberId){
      this.echo("reminder."+memberId, ".created", ({ response }) => {
          this.reminder = response.data;
          this.isVisible = true;
      });
    }
  },
  methods: {
    _cancel() {
       this.isVisible = false;
    },
    _formatTime(start, end) {
        return (
            this.dateInstance(start).format("HH:mm") +
            " - " +
            this.dateInstance(end).format("HH:mm")
        );
    },
  },
};
</script>
